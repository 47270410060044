.App {
  text-align: center;
}

a:hover {
  color: red;
  transform: scale(1.05);
  transition: color 0.2s ease, transform 0.2s ease;
}

.header-link:hover {
  color: red !important;
  transform: scale(1.02);
}

.footer-link:hover {
  color: red !important;
  transform: scale(1.02);
}

.ticker-container {
  background-color: #fffbfb;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  width: 100%;
  color: #a11a02;
  margin-top: 30px;
}

.ticker-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 35s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  width: 100%;
  background-color: #fff;
  height: auto;
  margin-top: 10px;
  overflow: hidden;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  color: black;
  text-align: center;
  width: 100%;
}

.footer-container.mobile {
  flex-direction: column;
  padding: 10px;
}

.footer-container.desktop {
  display: flex;
  justify-content: space-around;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.footer-logo-section img {
  margin-top: 15%;
  width: 100%;
}

.footer-social-icons {
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
  padding: 5% 10%;
  margin-top: 30px;
}

.footer-social-icons a {
  cursor: pointer;
  font-size: 24px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.footer-columns.mobile {
  flex-direction: column;
}

.footer-columns.desktop {
  flex-direction: row;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 5% 0;
}

.footer-column-title {
  font-weight: bold;
  font-size: large;
  margin-bottom: 20px;
}

.footer-link {
  color: #4a4c64;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
}

.footer-link:hover {
  color: #000;
  text-decoration: underline;
}

.footer-contact-link {
  color: #4a4c64;
  text-decoration: none;
  font-weight: 600;
}

.footer-contact-link:hover {
  text-decoration: underline;
}

.footer-copyright {
  display: flex;
  justify-content: space-evenly;
  color: black;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -2px 4px 0px;
}

.footer-copyright-link {
  color: grey;
  text-decoration: none;
}


.about-us-container {
  padding: 50px;
  box-sizing: border-box;
}

.image-text-section {
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
  align-items: center; /* Center vertically */
  margin-bottom: 50px; /* Space between sections */
}

.image-container {
  flex: 1; /* Adjusts the width of the image container */
}

.section-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.text-container {
  flex: 2;
  padding-left: 20px;
}

.text-container h2 {
  margin: 0 0 10px;
}

.text-container p {
  margin: 0 0 15px;
}

.clients-section {
  margin-top: 50px;
}

.clients-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.client-image {
  width: 150px;
  height: 100px;
}

@media (max-width: 768px) {
  .image-text-section {
      flex-direction: column;
  }

  .text-container {
      padding-left: 0;
      padding-top: 20px;
  }

  .about-us-container {
      padding: 20px;
  }
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0px;
  width: 100%;
}

.contact-container.mobile {
  flex-direction: column;
}

.contact-details {
  background-color: #c8d8de;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
}

.contact-details.mobile {
  width: 100%;
}

.contact-details.desktop {
  width: 35%;
  height: auto;
}

.contact-details h2 {
  color: white;
  font-weight: 600;
}

.contact-divider {
  border-top: 1.5px solid white;
  margin: 2px 0px;
  width: 80%;
}

.contact-info {
  margin-top: 20px;
  color: white;
}

.comment-section {
  margin-top: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  height: auto;
}

.review {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.R-title {
  text-align: center;
  margin-bottom: 20px;
}

.media-review {
  display: flex;
  margin-bottom: 20px;
  height: auto;
}

.media-user img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.media-body {
  margin-left: 20px;
  flex: 1;
  height: auto;
}

.M-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.rating-row ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.rating-row li {
  margin-right: 5px;
}

.description {
  margin-top: 10px;
  font-size: 16px;
}
