.hamburger {
    cursor: pointer;
    display: block;
    width: "20%";
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #fae8e3;
    top: 100px; 
    right: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.mobile-menu li {
    padding: 10px 20px;
    text-align: left;
}

.mobile-menu li p {
    margin: 0;
    cursor: pointer;
    color: red;
}


.portal-button {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 800;
    /* width: 150px; */
    /* height: 50px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50px;
}

/* .portal-button:hover {
    background-color: #0056b3;
} */