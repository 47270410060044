* {
  box-sizing: border-box;
  font-family: "Quicksand", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.scrollable-card {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

button {
  cursor: pointer;
}

.services-button {
  padding: 10px 20px;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.services-button:hover {
  background-color: #0056b3;
}
